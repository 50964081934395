import React from "react"
import ContactGeneral from "../../components/forms/contactgeneral"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

const JoinPage = () => {
  return (
    <Layout>
      <SEO title="Join Platform - PCS" description="" lang="en" meta={[]} />

      {/* Hero */}
      <div className="banner-image secondary pcs">
        <div className="hero-text">
          <h1>Collaborate with PCS</h1>
          <p>
            Looking to make an impact in the data computing and scientific
            community? We are happy to take on what help we can get with this
            ambitious project.
          </p>
        </div>
      </div>

      {/* Form Section */}
      <section>
        <div className="container">
          <div className="row">
            <h2 className="text-center">
              What does collaborating with PCS look like?
            </h2>
            <p className="mb-5">
              Lorem ipsum dolor sit amet consectetur, adipisicing elit. Harum
              assumenda sapiente adipisci repudiandae, modi quo vero! Eaque
              veniam nam quas nemo accusantium aspernatur adipisci aut
              voluptatem?
            </p>
            <ContactGeneral />
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default JoinPage
